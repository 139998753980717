import { useState } from 'react';
import crypto from 'crypto';
import { useMutation } from '@apollo/client';
import { UPLOAD_SOCIAL_MEDIA_PUBLIC_KEY } from '@app/config';
import INSTAGRAM_AUTH from '@graphql/instagramAuth.graphql';
import style from './style.module.css';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const useInstagramAuth = ({ onCompleted = () => {} }) => {

    const [auth] = useMutation(INSTAGRAM_AUTH, { onCompleted: (data) => onCompleted(data?.instagramAuth) });

    const instagramAuth = (username, password) => {
      auth({
        variables: {
          username,
          password,
        }
      });
    }

    return [instagramAuth];
}

const Instagram = ({ t }) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [credentials, setCredentials] = useState(null);
    const [error, setError] = useState(false);
    const { search } = useLocation();
    const [instagramAuth] = useInstagramAuth({
      onCompleted: (data) => {
        if (!data) {
          setError(true);
        } else {
          const query = new URLSearchParams(search);
          const redirectUri = query.get('redirect_uri');
          if (redirectUri) {
            window.location.href = encodeURI(`${redirectUri}?username=${credentials.username}&password=${credentials.password}`);
          }
        }
      }
    });

    const onSubmit = () => {

        setError(false);

        const encryptedPassword = crypto.publicEncrypt({
          key: Buffer.from(UPLOAD_SOCIAL_MEDIA_PUBLIC_KEY, 'base64'),
          padding: crypto.constants.RSA_PKCS1_PADDING,
        }, Buffer.from(password));

        setCredentials({
          username: username,
          password: encryptedPassword.toString('base64'),
        });

        instagramAuth(username, encryptedPassword.toString('base64'));
    }

    return (
        <div className={style.container}>
            <h1>{t('Log in to Instagram')}</h1>
            <label htmlFor='username'>{t('Username')}</label>
            <input type='text' name='username' value={username} onChange={(event) => setUsername(event.target.value)} />
            <label htmlFor='password'>{t('Password')}</label>
            <input type='password' name='password' value={password} onChange={(event) => setPassword(event.target.value)} />
            <button onClick={onSubmit}>{t('Log in')}</button>
            {error && <p className={style.error}>{t('Invalid username or password')}</p>}
        </div>
    );
}

export default withTranslation()(Instagram);