import i18n from 'i18next';
import { makePlugins } from '@skeepers/i18n/i18next';
import { initReactI18next as react } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';

import { API_URL } from '@app/config';

const { backend, postProcessor, detector, initOptions } = makePlugins({
  url: API_URL,
  template: 'backoffice',
});

export const init = () =>
  i18n
    .use(backend)
    .use(detector)
    .use(postProcessor)
    .use(react)
    .on('languageChanged', (lng) => {
      moment.locale(lng);
    })
    .init({
      ...initOptions,
      react: {
        useSuspense: false,
      },
    });

export default i18n;
