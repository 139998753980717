import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Instagram from '@views/Instagram';
import Tiktok from '@views/Tiktok'

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path='/instagram'>
            <Instagram />
          </Route>
          <Route path='/tiktok'>
            <Tiktok />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
