import { useState } from 'react';
import crypto from 'crypto';
import { useMutation } from '@apollo/client';
import { UPLOAD_SOCIAL_MEDIA_PUBLIC_KEY } from '@app/config';
import TIKTOK_AUTH from '@graphql/tiktokAuth.graphql';
import TIKTOK_CAPTCHA from '@graphql/tiktokCaptcha.graphql';
import style from './style.module.css';
import { useLocation } from 'react-router-dom';
import CaptchaSlide from './Captcha/Slide';

const useTiktokCaptcha = ({ onCompleted = () => {} }) => {

    const [captchaVerify] = useMutation(TIKTOK_CAPTCHA, { onCompleted: (data) => onCompleted(data?.tiktokCaptcha) });

    const tiktokCaptcha = (captcha, sessionId) => {
        captchaVerify({
            variables: {
                captcha,
                sessionId,
            }
        });
    }

    return [tiktokCaptcha];
}

const useTiktokAuth = ({ onCompleted = () => {} }) => {

    const [auth] = useMutation(TIKTOK_AUTH, { onCompleted: (data) => onCompleted(data?.tiktokAuth) });

    const tiktokAuth = (username, password) => {
        auth({
          variables: {
            username,
            password,
          }
        });
    }

    return [tiktokAuth];
}

const Tiktok = ({ t = (_) => _ }) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [captcha, setCaptcha] = useState(null);
    const [sessionId, setSessionId] = useState('');
    const { search } = useLocation();
    const [tiktokAuth] = useTiktokAuth({
        onCompleted: (data) => {
            setCaptcha(JSON.parse(data.captcha));
            setSessionId(data.sessionId);
        }
    });
    const [tiktokCaptcha] = useTiktokCaptcha({
        onCompleted: (data) => {

            if (data.captcha !== '') {
                setCaptcha(JSON.parse(data.captcha));
            } else if (!data.cookies) {
                setError(true);
            } else {
                const query = new URLSearchParams(search);
                const redirectUri = query.get('redirect_uri');
                if (redirectUri) {
                    window.location.href = encodeURI(`${redirectUri}?credentials=${Buffer.from(JSON.stringify({...data, username})).toString('base64')}`);
                }
            }
        }
    });

    const onSubmit = () => {

        setError(false);

        const encryptedPassword = crypto.publicEncrypt({
            key: Buffer.from(UPLOAD_SOCIAL_MEDIA_PUBLIC_KEY, 'base64'),
            padding: crypto.constants.RSA_PKCS1_PADDING,
          }, Buffer.from(password));

        tiktokAuth(username, encryptedPassword.toString('base64'));
    }

    const onCaptchaResolve = (captchaResolved) => {

        tiktokCaptcha(JSON.stringify(captchaResolved), sessionId);
    }

    const captchaForm = () => {

        if (!captcha) {
            return null;
        }

        switch (captcha.mode) {
            default:
                return null;
            case 'slide':
                return <CaptchaSlide captcha={captcha} onCaptchaResolve={onCaptchaResolve} />
        }
    }

    return (
        <div className={style.container}>
            <h1>{t('Log in to Tiktok')}</h1>
            <label htmlFor='username'>{t('Username')}</label>
            <input type='text' name='username' value={username} onChange={(event) => setUsername(event.target.value)} />
            <label htmlFor='password'>{t('Password')}</label>
            <input type='password' name='password' value={password} onChange={(event) => setPassword(event.target.value)} />
            <button onClick={onSubmit}>{t('Log in')}</button>
            {error && <p className={style.error}>{t('Invalid username or password')}</p>}
            { captchaForm()}
        </div>
    );
}

export default Tiktok;