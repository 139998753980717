import style from './style.module.css';
import Draggable from 'react-draggable';
import { useRef } from 'react';
import { useState } from 'react/cjs/react.development';

const TIKTOK_IMG_WIDTH = 340;

const CaptchaSlide = ({ captcha, onCaptchaResolve }) => {

    const [backgroundWidth, setBackgroundWidth] = useState(null);
    const captchaRef = useRef();

    const onBackgroundLoad = ({ target }) => {
        setBackgroundWidth(target.naturalWidth);
    }

    const onStop = () => {
        const cssTransfrom = captchaRef.current.style.transform;
        const pos = parseInt(cssTransfrom.substring(cssTransfrom.indexOf('(') + 1, cssTransfrom.indexOf('px')));
        const ratio = TIKTOK_IMG_WIDTH / backgroundWidth;

        onCaptchaResolve({
            mode: captcha.mode,
            anwser: {
                x: ratio * pos,
            }
        });
    }

    return (
        <div className={style.container}>
            <img onLoad={onBackgroundLoad} className={style.captchaBackground} src={captcha.question.url1} alt='' />
            <Draggable axis='x' bounds={'parent'} onStop={onStop}>
                <img ref={captchaRef} className={style.captchaImage} style={{top: captcha.question.tip_y}} src={captcha.question.url2} alt='' draggable={false} />
            </Draggable>
        </div>
    );
}

export default CaptchaSlide;