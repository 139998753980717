import React from 'react';
import ReactDOM from 'react-dom';
import App from '@app/App';

import client from '@app/apollo';
import { ApolloProvider } from '@apollo/client';
import { init as i18n } from '@app/i18n';

/**
 * Initialize i18next
 */
const initI18n = i18n;

Promise.allSettled([initI18n]).then(() => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,
  document.getElementById('root')
  );
});
